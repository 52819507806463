import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shailesh/Dev/Projects/DopeAi/Repos/dopeai-web/src/templates/ghost/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "../privacy-old-14-01-2023"
      }}>{`Previous Version (Jan 14, 2023)`}</a></p>
    <p>{`This is `}<strong parentName="p">{`Outdated Version`}</strong>{`, please check `}<a parentName="p" {...{
        "href": "../privacy"
      }}>{`Latest Version`}</a></p>
    <p>{`This Privacy Policy describes how `}<strong parentName="p">{`BookMyShoot Technologies Private Limited`}</strong>{` (the "Company", "us", "we", or "our") collects, uses, stores, shares and protects your personal information in connection with your use of both the website with the `}<a parentName="p" {...{
        "href": "https://dopeai.tech"
      }}>{`dopeai.tech`}</a>{` domain name (the “Site”) and the services we may offer through the Site from time to time, consisting of Applications("Apps"), Products and other services (indistinctly referred to as the “Services”). `}</p>
    <p>{`For marketing and delivery of the Licensed Applications to End-Users, we have appointed Apple and Apple Subsidiaries (collectively "Apple") as our Agent and Commissionaire. For your purchase related data and privacy you should check `}<a parentName="p" {...{
        "href": "https://www.apple.com/legal/privacy/"
      }}>{`Apple's privacy policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "what-data-do-we-collect",
      "style": {
        "position": "relative"
      }
    }}>{`What data do we collect?`}<a parentName="h2" {...{
        "href": "#what-data-do-we-collect",
        "aria-label": "what data do we collect permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`We collect following Data linked to you`}</p>
    <ul>
      <li parentName="ul">{`Contact Info - Name, Email ID when you sign into our Services`}</li>
      <li parentName="ul">{`Identifiers - We maintain App User ID for every user signed in to our Services.`}</li>
      <li parentName="ul">{`Purchases - Purchase history on our Services. This does not include actual payment method details, those stay with Apple.`}</li>
      <li parentName="ul">{`Usage Data - Usage data include your interactions with Site and Services.`}</li>
      <li parentName="ul">{`Diagnostics - Diagnostics data include Crash and Performance information of our Site and Services.`}</li>
    </ul>
    <p>{`Usage and Diagnostics Data may include Device, OS and Developer Platform information`}</p>
    <p>{`We collect following Data not linked to you`}</p>
    <ul>
      <li parentName="ul">{`Contact Info - Email ID when you subscribe to our newsletters`}</li>
    </ul>
    <h2 {...{
      "id": "what-data-we-dont-collect",
      "style": {
        "position": "relative"
      }
    }}>{`What data we don't collect?`}<a parentName="h2" {...{
        "href": "#what-data-we-dont-collect",
        "aria-label": "what data we dont collect permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`We don't collect, store, or share any photos you import in our Services.`}</li>
      <li parentName="ul">{`We don't collect, store, or share actual payment method details, those are collected by Apple and stay with them. For e.g. when you pay for OptiCull Pro, we just get to know that you purchased this product but actual payment details like your Credit Card details stay with Apple.`}</li>
    </ul>
    <h2 {...{
      "id": "how-do-we-collect-your-data",
      "style": {
        "position": "relative"
      }
    }}>{`How do we collect your data?`}<a parentName="h2" {...{
        "href": "#how-do-we-collect-your-data",
        "aria-label": "how do we collect your data permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`Your Personal Information is collected directly from you when you enter it on Site or Services to access certain feature.`}</li>
      <li parentName="ul">{`Contact Info - When you provide us your email id for subscribing to our newsletters.`}</li>
      <li parentName="ul">{`Contact Info - When you sign into our services, we store your Name and Email ID. You can keep Name and Email ID private using Sign In With Apple.`}</li>
      <li parentName="ul">{`Purchases - When you make payment or update existing subscriptions.`}</li>
      <li parentName="ul">{`Usage Data - When you interact with our Site and Services.`}</li>
      <li parentName="ul">{`Diagnostics - When you interact with our Site and Services. Performance data may be collected per interaction and Crash data will be collected when interaction results in an abnormal behavior.`}</li>
    </ul>
    <h2 {...{
      "id": "how-will-we-use-your-data",
      "style": {
        "position": "relative"
      }
    }}>{`How will we use your data?`}<a parentName="h2" {...{
        "href": "#how-will-we-use-your-data",
        "aria-label": "how will we use your data permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`Your Personal Information will be used for the feature you gave that information. For e.g. If you have signed up of the newsletter, we will use your email id when sending newsletter to you. `}<em parentName="li">{`App Functionality`}</em></li>
      <li parentName="ul">{`Contact Info - To provide app functionality like authenticating you. `}<em parentName="li">{`App Functionality`}</em></li>
      <li parentName="ul">{`Contact Info - To communicate important updates related to Services. For `}<em parentName="li">{`Advertising and Marketing`}</em>{` of our Services.`}</li>
      <li parentName="ul">{`Purchases - To lock or unlock paid content. `}<em parentName="li">{`App Functionality`}</em>{` `}</li>
      <li parentName="ul">{`Purchases - To analyze pricing and campaign performance. `}<em parentName="li">{`Analytics`}</em></li>
      <li parentName="ul">{`Usage Data - For analyzing and improving quality and usability of Site and Services. `}<em parentName="li">{`App Functionality`}</em>{` `}<em parentName="li">{`Analytics`}</em></li>
      <li parentName="ul">{`Diagnostics - For analyzing and improving reliability and availability of Site and Services. `}<em parentName="li">{`App Functionality`}</em>{` `}<em parentName="li">{`Analytics`}</em></li>
    </ul>
    <h2 {...{
      "id": "how-do-we-store-your-data",
      "style": {
        "position": "relative"
      }
    }}>{`How do we store your data?`}<a parentName="h2" {...{
        "href": "#how-do-we-store-your-data",
        "aria-label": "how do we store your data permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <ul>
      <li parentName="ul">{`Your email id for the purpose of newsletter is stored with our outbound email service like `}<a parentName="li" {...{
          "href": "https://www.sendinblue.com/legal/privacypolicy/"
        }}>{`SendInBlue`}</a>{`.`}</li>
      <li parentName="ul">{`Sign In email and name is stored in `}<a parentName="li" {...{
          "href": "https://firebase.google.com/support/privacy"
        }}>{`Firebase Authentication`}</a>{`.`}</li>
      <li parentName="ul">{`Purchases data is stored in `}<a parentName="li" {...{
          "href": "https://www.apple.com/legal/privacy/"
        }}>{`Apple`}</a>{` and `}<a parentName="li" {...{
          "href": "https://www.revenuecat.com/privacy/"
        }}>{`RevenueCat`}</a></li>
      <li parentName="ul">{`Usage Data for Site is stored in `}<a parentName="li" {...{
          "href": "https://firebase.google.com/support/privacy"
        }}>{`Google Analytics for Firebase`}</a>{`.`}</li>
      <li parentName="ul">{`Usage Data for Services is stored in `}<a parentName="li" {...{
          "href": "https://firebase.google.com/support/privacy"
        }}>{`Google Analytics for Firebase`}</a>{`, `}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/appcenter/gdpr/data-from-your-end-users"
        }}>{`Microsoft App Center`}</a>{` and `}<a parentName="li" {...{
          "href": "https://mixpanel.com/legal/privacy-policy"
        }}>{`MixPanel`}</a>{`.`}</li>
      <li parentName="ul">{`Diagnostics Data is stored in `}<a parentName="li" {...{
          "href": "https://docs.microsoft.com/appcenter/gdpr/data-from-your-end-users"
        }}>{`Microsoft App Center`}</a>{`, `}<a parentName="li" {...{
          "href": "https://firebase.google.com/support/privacy"
        }}>{`Firebase Crashlytics`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.apple.com/legal/privacy/"
        }}>{`Apple`}</a>{` and `}<a parentName="li" {...{
          "href": "https://sentry.io/privacy/"
        }}>{`Sentry`}</a></li>
    </ul>
    <h2 {...{
      "id": "marketing",
      "style": {
        "position": "relative"
      }
    }}>{`Marketing`}<a parentName="h2" {...{
        "href": "#marketing",
        "aria-label": "marketing permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`We will send you emails about updates to Services if you sign up for our newsletters or Services. You can opt out of specific type of communications. These are not frequent and we don't spam.`}</p>
    <h2 {...{
      "id": "what-are-your-data-protection-rights",
      "style": {
        "position": "relative"
      }
    }}>{`What are your data protection rights?`}<a parentName="h2" {...{
        "href": "#what-are-your-data-protection-rights",
        "aria-label": "what are your data protection rights permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:`}</p>
    <p><strong parentName="p">{`The right to access`}</strong>{` – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.`}</p>
    <p><strong parentName="p">{`The right to rectification`}</strong>{` – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.`}</p>
    <p><strong parentName="p">{`The right to erasure`}</strong>{` – You have the right to request that Our Company erase your personal data, under certain conditions.`}</p>
    <p><strong parentName="p">{`The right to restrict processing`}</strong>{` – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.`}</p>
    <p><strong parentName="p">{`The right to object to processing`}</strong>{` – You have the right to object to Our Company’s processing of your personal data, under certain conditions.`}</p>
    <p><strong parentName="p">{`The right to data portability`}</strong>{` – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.`}</p>
    <p>{`If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email:`}</p>
    <p>{`Write to us: `}<a parentName="p" {...{
        "href": "mailto:privacy@dopeai.tech"
      }}>{`privacy@dopeai.tech`}</a></p>
    <h2 {...{
      "id": "cookies",
      "style": {
        "position": "relative"
      }
    }}>{`Cookies`}<a parentName="h2" {...{
        "href": "#cookies",
        "aria-label": "cookies permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Cookies are small text files that are generated when you access the Site and that collect your browsing information. All cookies used by us are safe for your computer and only process information which is stored on your internet browser. Our cookies cannot execute code, do not contain malware or viruses, and cannot be used to access content on your computer.`}</p>
    <p>{`For further information, visit `}<a parentName="p" {...{
        "href": "https://allaboutcookies.org"
      }}>{`allaboutcookies.org`}</a>{`.`}</p>
    <h2 {...{
      "id": "what-types-of-cookies-do-we-use",
      "style": {
        "position": "relative"
      }
    }}>{`What types of cookies do we use?`}<a parentName="h2" {...{
        "href": "#what-types-of-cookies-do-we-use",
        "aria-label": "what types of cookies do we use permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`We use our own and those of third parties, as described below:`}</p>
    <p><strong parentName="p">{`Strictly necessary cookies`}</strong>{`: are those cookies needed to ensure you can access the Site, and browse it securely. These cookies are strictly necessary, as the use and access to the Site and the services provided through the Site require them. They also protect us from any fraudulent use of the Site or our services, to verify that anyone using your account is actually you, and protect your data from any unauthorized users. For instance, technical cookies are those relating to the communication and exchange of data, or those required to verify your identity when you sign in into your account.`}</p>
    <p><strong parentName="p">{`Cookies that remember your settings (functional cookies)`}</strong>{`: are those cookies that are installed and used to adapt the Site and the services offered by us to your preferences, such as language, or look and feel of the Site. You can opt to block or limit the installation and use of these cookies as explained in section ‘How can you block or delete cookies’ below, and this shall not impact the usability or functionalities of the Site and/or the services, but your preferences will be lost. `}</p>
    <p><strong parentName="p">{`Cookies that measure website use (analytics cookies)`}</strong>{`: are those cookies used for tracking, monitoring, and analyzing how you browse and interact with the Site and our services. They reveal usage trends as well as which users upgrade the services rendered by us and how is this done. You can opt to block or limit the installation and use of these cookies as explained in section ‘How can you block or delete cookies’ below, and this shall not impact the usability or functionalities of the Site and/or the services. `}</p>
    <p>{`We use third party cookies for the purposes mentioned above. Said cookies are installed, used and owned by: `}</p>
    <p>{`Google - To know more about Google’s cookies, please visit its cookies and privacy policies, as available `}<a parentName="p" {...{
        "href": "https://policies.google.com/technologies/types"
      }}>{`here`}</a>{`. Please note that the processing of data by Google may entail the international transfer of data outside the European Union, as further described in the link above; and`}</p>
    <p><strong parentName="p">{`Cookies that help with our communications and marketing (advertising and profiling cookies)`}</strong>{`: refers to cookies aimed at tracking, monitoring, and analyzing how you browse and interact with the Site and our services, as well as segment our users based on their behavior and how they browse the site, and to build audiences. They reveal usage trends as well as which users upgrade the services rendered by us and how is this done. All those actions are aimed at better understanding our users for improve communications and marketing strategies. You can opt to block or limit the installation and use of these cookies as explained in section ‘How can you block or delete cookies’ below, and this shall not impact the usability or functionalities of the Site and/or the services. `}</p>
    <p>{`We use third party cookies for the purposes mentioned above. Said cookies are installed, used and owned by: `}</p>
    <p>{`Facebook - To know more about Facebook’s cookies, please visit its cookies and privacy policies, as available `}<a parentName="p" {...{
        "href": "https://www.facebook.com/policy/cookies/"
      }}>{`here`}</a>{`. Please note that the processing of data by Facebook may entail the international transfer of data outside the European Union, as further described in the link above; `}</p>
    <p>{`Google - To know more about Google’s cookies, please visit its cookies and privacy policies, as available `}<a parentName="p" {...{
        "href": "https://policies.google.com/technologies/types?hl=en"
      }}>{`here`}</a>{`. Please note that the processing of data by Google may entail the international transfer of data outside the European Union, as further described in the link above; `}</p>
    <p>{`LinkedIn - To know more about LinkedIn’s cookies, please visit its cookies and privacy policies, as available `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/legal/cookie-policy"
      }}>{`here`}</a>{`. Please note that the processing of data by LinkedIn may entail the international transfer of data outside the European Union, as further described in the link above; and`}</p>
    <h2 {...{
      "id": "how-can-you-block-or-delete-cookies",
      "style": {
        "position": "relative"
      }
    }}>{`How can you block or delete cookies?`}<a parentName="h2" {...{
        "href": "#how-can-you-block-or-delete-cookies",
        "aria-label": "how can you block or delete cookies permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`You can allow, block, or delete cookies at any time by configuring your browser settings, as well as by means of the cookie banner. As mentioned in section 2 above, blocking or deleting some cookies may impact your ability to access and/or use the Site or the services offered by us. You can find more information on how to block or deactivate cookies below:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
        }}>{`Internet Explorer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/en-us/HT201265"
        }}>{`Safari`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
        }}>{`Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
        }}>{`Firefox`}</a></li>
    </ul>
    <h2 {...{
      "id": "privacy-policies-of-other-websites",
      "style": {
        "position": "relative"
      }
    }}>{`Privacy policies of other websites`}<a parentName="h2" {...{
        "href": "#privacy-policies-of-other-websites",
        "aria-label": "privacy policies of other websites permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Our Site and Services contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.`}</p>
    <h2 {...{
      "id": "childrens-privacy",
      "style": {
        "position": "relative"
      }
    }}>{`Children's Privacy`}<a parentName="h2" {...{
        "href": "#childrens-privacy",
        "aria-label": "childrens privacy permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Only persons age 18 or older have permission to access our Site and Services. Our Site and Services does not address anyone under the age of 13 ("Children").`}</p>
    <p>{`We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian, and you learn that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a children under age 13 without verification of parental consent, we take steps to remove that information.`}</p>
    <h2 {...{
      "id": "changes-to-our-privacy-policy",
      "style": {
        "position": "relative"
      }
    }}>{`Changes to our privacy policy`}<a parentName="h2" {...{
        "href": "#changes-to-our-privacy-policy",
        "aria-label": "changes to our privacy policy permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`We keep this privacy policy under regular review and update it on this web page. We will also update this privacy policy as and when we add more Services. You can check previous version of this privacy policy linked at start of this page.`}</p>
    <h2 {...{
      "id": "how-to-contact-us",
      "style": {
        "position": "relative"
      }
    }}>{`How to contact us`}<a parentName="h2" {...{
        "href": "#how-to-contact-us",
        "aria-label": "how to contact us permalink",
        "className": "ml-2 opacity-0 group-hover:opacity-90 inline-block after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`If you have any questions about Our privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.`}</p>
    <p>{`Email us at: `}<a parentName="p" {...{
        "href": "mailto:privacy@dopeai.tech"
      }}>{`privacy@dopeai.tech`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      