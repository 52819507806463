import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Layout from "../../components/layout"
import { Section } from "../../components/section"
import { components } from "../../utils/mdx-components"
import SEO from "../../components/SEO/SEO"

export default function PageLayout({ children, location, pageContext }) {
  return (
    <Layout>
      <SEO
        title={pageContext.frontmatter.title}
        pathname={location.pathname}
        desc={pageContext.frontmatter.description}
      />
      <Section nogrid noborder>

        <article className="post tag-billing-account featured single md:px-4">

          <header className="post-header container medium max-w-7xl mx-auto mb-6">
            <h1 className="text-4xl font-extrabold text-gray-900 mb-3">{pageContext.frontmatter.title}</h1>
            {pageContext.frontmatter.lastupdate && (<p className="text-lg text-gray-500">
              Last updated: <b>{pageContext.frontmatter.lastupdate}</b>
            </p>)}
          </header>
          <div className="post-content kg-canvas u-text-format text-lg text-gray-500 leading-8 max-w-7xl mx-auto">
            <MDXProvider components={components}>{children}</MDXProvider>
          </div>

        </article>

      </Section>

    </Layout>
  )
}